.home-text {
    font-weight: 400;
    cursor: pointer;
    color: white;
}
.link-text {
    font-weight: 300;
}
a {
     text-decoration: none;
     color: inherit;
}

.dropdown-title {
    font-weight: 300 !important;
    font-size: small !important;
}