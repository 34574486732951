.branch {
  padding: 0.6rem 0;
}

.block {
  width: 100%;
  display: flex;
  justify-content: center;
}

.active {
  background: #454A4D;
}
