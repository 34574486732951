.window {
  flex: 1;
  overflow-y: auto;
  height: '100%';
}

.box-overflow {
    height: 100%;
    width: 100%;
    overflow: auto;
}
