.component {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    cursor: pointer;
}

.empty {
  color: rgba(0, 0, 0, 0.6);
}
