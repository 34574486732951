.form-row {
    margin-top: 15px !important;
}

.form-input {
    max-width: 150px;
}

.importance-text {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 5px;
    text-align: center;
    text-transform: uppercase;
}